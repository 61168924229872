import Layout from "../components/Layout"
import SEO from "../components/Seo"
import { Swiper } from "../utils/Swiper"
import { Scroller } from "../utils/Scroller"
import "./about.scss"
import "bootstrap/dist/css/bootstrap.css"
import ReactBodymovin from 'react-bodymovin'
import animation from "../images/alex_liang_2.json"
import animation_luv from "../images/Luveia Card 1.json"
import lottie, { AnimationItem } from "lottie-web"
import Alex_Card from "../images/alex_liang.png"

import React, {useEffect, createRef, useRef} from "react"


const About : React.FC = () => {
  let anim: AnimationItem;
  let anim_luv: AnimationItem;
  let startframe = 0;
  let endframe = 60;
  let startframe1 = 0;
  let endframe1 = 60
  useEffect(() => {
    anim = lottie.loadAnimation({
      container: animBox.current as HTMLDivElement,
      renderer: "svg",
      loop: false,
      autoplay: false,
      animationData: animation
    });
    anim_luv = lottie.loadAnimation({
      container: animBox_luv.current as HTMLDivElement,
      renderer: "svg",
      loop: false,
      autoplay: false,
      animationData: animation_luv
    });
    return () => anim.destroy(); // optional clean up for unmounting
  }, []);
  function handlePlay(){
    
    anim.playSegments([startframe,endframe], true)
    if(startframe == 0){
      startframe = 60;
      endframe = 0;
    }
    else{
      startframe = 0;
      endframe = 60
    }
    
  }
  function handlePlay2(){
    
    anim_luv.playSegments([startframe1,endframe1], true)
    if(startframe1 == 0){
      startframe1 = 60;
      endframe1 = 0;
    }
    else{
      startframe1 = 0;
      endframe1 = 60
    }
    
  }

  

  

  
  

 
  
  

  
    
    let animBox = createRef<HTMLDivElement>()
    let animBox_luv = createRef<HTMLDivElement>()
    return (
      <Layout>
        <SEO title="About" />
        
        

      

      
      <section className="ft-legal">
        <ul className="ft-legal-list">
          <li>ABOUT</li>
        </ul>
      </section>
      <section className="ft-social">
        <ul className="ft-social-list">
        <div className = "fluid-container">
        <div style={{width: 400, margin: '0 auto'}} ref={animBox} onClick={()=>handlePlay()}></div>
        </div>
        <div className = "fluid-container">
        <div ref={animBox_luv} onClick={()=>handlePlay2()}></div>
        </div>
        
        
          
        </ul>
      </section>
    
      

        
      </Layout>
    )
  
}

export default About
